import React from "react";
import GuessNumberAd from "../assets/guess-number-ad.jpg"

const Advertise = () => {
    return (
        <div className="flex flex-col">
            <a href="https://amzn.to/47KcF1G" target="_blank" rel="noreferrer">
                <img
                    src={GuessNumberAd}
                    alt="Guess Number Ad"
                    className="w-[450px] h-[200px] ml-auto mr-auto"
                />
            </a>
           
            <span className="text-lg text-gray-500">Copyright CPC Systems</span><br/><br/>
        </div>
    )
}

export default Advertise;
