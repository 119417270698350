import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Home, Play, Success, Over, MaxPlays } from "./pages";

function App() {
  const [gamePlays, setGamePlays] = useState(0);
  const [gameTime, setGameTime] = useState();
  const [spentTime, setSpentTime] = useState(0);
  const [guessNumber, setGuessNumber] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const savedNumber = localStorage.getItem('game');
    const savedTime = localStorage.getItem('gameTime');

    if (savedNumber && savedTime) {
      const timeElapsed = Date.now() - Number(savedTime);
      if (timeElapsed < 24 * 60 * 60 * 1000) {
        setGamePlays(Number(savedNumber));
      } else {
        localStorage.removeItem('game');
        localStorage.removeItem('gameTime');
        navigate("/max");
      }
    }
  }, [navigate]);

  const handleIncrement = () => {
    if (gamePlays <= 100) {
      const updatedNumber = gamePlays + 1;
      setGamePlays(updatedNumber);
      localStorage.setItem('game', updatedNumber.toString());
      localStorage.setItem('gameTime', Date.now().toString());
    }
  }

  return (
    <div>
      {gamePlays <= 100 ? (
        <Routes>
          <Route
            path="/"
            element={
              <Home
                setMin={setGameTime}
                onIncrement={handleIncrement}
              />
            }
          />
          <Route
            path="/play"
            element={
              <Play
                min={gameTime}
                setSpentTime={setSpentTime}
                gameTime={gameTime}
                setGuessNumber={setGuessNumber}
              />
            }
          />
          <Route
            path="/success"
            element={
              <Success
                gameTime={gameTime}
                spentTime={spentTime}
                guessNumber={guessNumber}
              />
            }
          />
          <Route
            path="/over"
            element={
              <Over
                gameTime={gameTime}
                spentTime={spentTime}
                guessNumber={guessNumber}
              />
            }
          />
          <Route path="/max" element={<MaxPlays />} />
        </Routes>
      ) : <MaxPlays />}
    </div>
  );
}

export default App;
