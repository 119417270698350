import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../components/Header";
import Advertise from '../components/Advertise';
import GameSuccessLogo from "../assets/guess-number-game-success.jpg";
import ReStartBtn from "../assets/buttons/PlayAgnRed.png";

const Success = ({ gameTime, spentTime, guessNumber }) => {
    const navigate = useNavigate();

    const handleRestart = () => {
        navigate("/")
    }

    return (
        <div className="h-screen flex flex-col items-center justify-center px-2">
            {/* Header with logo and Date*/}
            <div className=" w-full max-w-lg text-center">
                <Header />
            </div>

            {/* Instructions section */}
            <div className="flex flex-col items-center w-full max-w-lg text-center">

                <img
                    src={GameSuccessLogo}
                    alt="Guess Number Logo"
                    className="w-[400px] h-[200px] mt-1 rounded-3xl"
                />

                <div className="flex flex-col text-sm text-gray-500 font-bold">
                    <span>Your Timer Was Set At: {gameTime === '0.5' ? '00:30' : gameTime + ':00' }</span>
                    <span>Time Elapsed was: {spentTime}</span>
                    <span>Number Of Guesses Used: {guessNumber} </span>
                </div>

                {/* Start button */}

                <br/><img
                    src={ReStartBtn}
                    alt='Restart'
                    className='mr-auto ml-auto w-[180px] h-[40px] cursor-pointer mt-1'
                    onClick={handleRestart}
                />

                {/* Ad Space */}
                <div className="w-full max-w-lg text-center mt-1">
                    <Advertise />
                </div>
            </div>
        </div>
    );
};

export default Success;
