import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Advertise from "../components/Advertise";
import StartBtn from "../assets/buttons/StartBut.jpg"

const Home = ({ setMin, onIncrement }) => {
    const [formattedDate, setFormattedDate] = useState();
    const [timer, setTimer] = useState(1);
    const timerMinutes = [0, 1, 2, 3];
    const navigate = useNavigate();

    const handleChange = (e) => {
        setTimer(e.target.value);
        setMin(e.target.value);
    };

    const handleStart = () => {
        onIncrement();
        navigate("/play");
    }

    //Get Current Date e.g. Today is Monday, September 16, 2024
    useEffect(() => {
        const currenDate = new Date();
        const tempDate = new Intl.DateTimeFormat('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }).format(currenDate);

        setFormattedDate(tempDate);
    }, []);

    //Default timer to 1
    useEffect(() => {
        setMin(1);
    }, [setMin])

    return (
        <div className="flex flex-col items-center justify-center h-screen px-3">
            {/* Header with logo and Date*/}
            <div className="w1-full max-w-lg text-center">
                <Header />
                <p className="text-lg text-gray-500 font-bold th mt-2">
                    <span>GuessFour Guidelines<br/></span>
                </p>
            </div>

            {/* Instructions section */}
            <div className="w-full max-w-lg text-center">
                <p className="text-sm text-justify">
                    Take 5 chances to guess a 4-digit random number within 3 minutes.
                    Guesses cannot start with zero or have repeated digits.
                    After submitting each guess the number entered will be displayed with each 
                    digit color coded.&nbsp;
                    <span className="text-gray-500 font-bold">GRAY</span> digit means it's not in the random number;&nbsp;
                    <span className="text-blue-500 font-bold">BLUE</span> digit it's in number but in wrong position;&nbsp;
                    <span className="text-red-500 font-bold">RED</span> digit it's in number and in correct position.
                    When your guess is correct all digits will be in&nbsp;
                    <span className="text-red-500 font-bold">RED</span>. To play set TIMER at 0-3 then press START.
                </p>
                <p className="text-sm">
                    <br/>TIMER: 0=30Secs 1=1Min 2=2Min 3=3Min (default=1)
                </p>

                {/* Timer input */}
                <div className="flex justify-center items-center">
                    <label htmlFor="timer" className="mr-2 text-sm font-bold">TIMER:</label>
                    <select
                        className="h-10 w-12 border border-gray-300 text-gray-600 text-sm rounded-lg block px-1 focus:outline-none"
                        onChange={handleChange}
                    >
                        {timerMinutes.map((time, index) => {
                            return (
                                time === timer
                                    ?
                                    <option key={index} value={time === 0 ? 0.5 : time} selected>{time}</option>
                                    :
                                    <option key={index} value={time === 0 ? 0.5 : time}>{time}</option>
                            )
                        })}
                    </select>
                </div>
            </div>
            {/* Start button */}
            
            <img
                src={StartBtn}
                alt='Start Button'
                className='mr-auto ml-auto mt-2 w-[200px] h-[50px] cursor-pointer'
                onClick={handleStart}
            />

            {/* Ad Space */}
            <div className="mt-2 w-full max-w-lg text-center">
                <Advertise />
            </div>
        </div>
    );
};

export default Home;
