import React, { useEffect, useState, useMemo, useCallback } from "react";
import Header from "../components/Header";
import { BackspaceIcon, ArrowTurnDownLeftIcon } from "@heroicons/react/24/solid";
import { useNavigate } from 'react-router-dom';

const Play = ({ min, setSpentTime, setGuessNumber, gameTime }) => {
    const [time, setTime] = useState(min * 60);
    const [answer, setAnswer] = useState(['', '', '', '']);
    const [guesses, setGuesses] = useState([]);
    const [randomNumber] = useState(generateRandomNumber());
    const [gameStatus, setGameStatus] = useState('progress');
    const navigate = useNavigate();


    //Handle Keypad Click event
    const handleKeypadClick = useCallback((para) => {
        const digit = para >= 0 && para <= 7 ? String(para + 1) : para === 9 ? '9' : para === 10 ? '0' : '';

        if (digit && para >= 0 && para <= 7) {
            const firstIndex = answer.indexOf('');
            const digitExists = answer.includes(digit);

            if (!digitExists) {
                setAnswer(prevAns => prevAns.map((ans, idx) =>
                    idx === firstIndex ? digit : ans
                ));
            }
        }

        if (para === 8) { // Backspace logic
            const lastIndex = answer
                .map((item, index) => ({ item, index }))
                .filter(({ item }) => item !== '')
                .reduce((last, current) => (current.index > last.index ? current : last), { index: -1 })
                .index;

            if (lastIndex !== -1) {
                setAnswer(prevAns => prevAns.map((ans, idx) =>
                    idx === lastIndex ? '' : ans
                ));
            }
        }

        if (para === 9) {
            const firstIndex = answer.indexOf('');
            const digitExists = answer.includes('9');

            if (!digitExists) {
                setAnswer(prevAns => prevAns.map((ans, idx) =>
                    idx === firstIndex ? '9' : ans
                ));
            }
        }

        if (para === 10) {
            const firstIndex = answer.indexOf('');
            const digitExists = answer.includes('0');

            if (!digitExists) {
                setAnswer(prevAns => prevAns.map((ans, idx) =>
                    (idx === firstIndex && firstIndex !== 0) ? '0' : ans
                ));
            }
        }

        if (para === 11) {
            if (!answer.includes('')) {
                const formattedAnswer = answer.map(ans => ({ num: ans, color: 'Gray' }));
                const updatedFormattedAnswer = formattedAnswer.map((guess, inx) => {
                    if (randomNumber.includes(String(guess.num))) {
                        return {
                            ...guess,
                            color: randomNumber.indexOf(String(guess.num)) === inx ? 'Red' : 'Blue'
                        };
                    }
                    return guess;
                });
                setGuesses(prevGuesses => [...prevGuesses, updatedFormattedAnswer]);
                setAnswer(['', '', '', '']);
            }

            if (gameStatus !== 'progress') {
                const navigateTo = gameStatus === 'success' ? "/success" : "/over";
                navigate(navigateTo);
            }
        }
    }, [answer, gameStatus, navigate, randomNumber]);

    //Time format e.g. 3:34
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;

        return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    }

    //Generate random number e.g. 8674
    function generateRandomNumber() {
        let num;
        do {
            num = Math.floor(Math.random() * (9876 - 1023 + 1)) + 1023;
        } while (new Set(String(num)).size !== 4);

        return num.toString();
    };

    const generateGridGuesses = useMemo(() => {
        let grid = [];
        const cols = 4;
        const rows = 5;

        for (let i = 0; i < rows; i++) {
            const guessRow = guesses[i] || (i === guesses.length ? answer.map(num => ({ num: num, color: 'gray' })) : undefined);
            for (let j = 0; j < cols; j++) {
                const guess = guessRow ? guessRow[j] : { num: '', color: 'gray' };
                const showNumber = guess.color === 'gray'; // Only show the number if color is gray

                grid.push(
                    <div
                        key={`${i}-${j}`}
                        className={`flex items-center justify-center border-2 rounded-lg h-14 text-3xl font-bold ${guess.color !== 'gray' ? `text-${guess.color}-500` : 'text-gray-500'}`}
                        style={guess.color !== 'gray' ? {
                            backgroundImage: `url(${require(`../assets/buttons/${guess.color}But${guess.num}.jpg`)})`,
                            backgroundSize: '100% 100%',
                            backgroundRepeat: 'no-repeat',
                            border: 'none',
                            backgroundPosition: 'center',
                        } : {}}
                    >
                        {showNumber ? guess.num : ''}
                    </div>
                );
            }
        }

        return grid;
    }, [guesses, answer]);



    const generateVirtualKeypad = () => {
        let keypad = [];
        for (let i = 0; i < 12; i++) {
            keypad.push(
                <button
                    key={i}
                    className="flex justify-center items-center bg-gray-500 h-9 text-white rounded text-3xl hover:bg-gray-600"
                    onClick={() => handleKeypadClick(i)}
                >
                    {i === 8 ? <BackspaceIcon className="size-6" />
                        : i === 9 ? i : i === 10 ? 0
                            : i === 11 ?
                                <div className="flex items-center gap-2">
                                    <span className="text-xs">Enter</span>
                                    <ArrowTurnDownLeftIcon className="size-5" />
                                </div>
                                : i + 1
                    }
                </button>
            );
        }

        return keypad;
    }


    //Handle game time
    useEffect(() => {
        let timeInterval;

        if (time > 0 && gameStatus === 'progress') {
            timeInterval = setInterval(() => {
                setTime(prevTime => prevTime - 1);
            }, 1000);

            return () => clearInterval(timeInterval);
        }

        if (gameStatus === 'success' || gameStatus === 'over') {
            clearInterval(timeInterval);
        }

        if (time === 0) {
            const initialTime = min * 60;
            const spentTime = initialTime - time;
            
            const formatSpentTime = () => {
                const minutes = Math.floor(spentTime / 60);
                const seconds = spentTime % 60;
                return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
            };

            setSpentTime(formatSpentTime());
            setGameStatus('over');
        }
    }, [navigate, time]);

    //Set Game Status
    useEffect(() => {
        if (guesses.length > 0) {
            const isWinningRow = guesses.some(guessRow =>
                guessRow.every(guess => guess.color === 'Red')
            );

            if (isWinningRow) {
                setGameStatus('success');
            }

            if (guesses.length >= 5 && !isWinningRow) {
                setGameStatus('over');
            }
        }
    }, [guesses]);

    useEffect(() => {
        const initialTime = min * 60;
        const spentTime = initialTime - time;

        const formatSpentTime = () => {
            const minutes = Math.floor(spentTime / 60);
            const seconds = spentTime % 60;
            return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
        };

        if (gameStatus === 'success' || gameStatus === 'over') {
            setSpentTime(formatSpentTime());
            setGuessNumber(guesses.length);
        }
    }, [gameStatus, navigate, setSpentTime, setGuessNumber, guesses, min, time]);
    


    return (
        <div className="flex flex-col items-center h-screen justify-center px-3">
            {/* Header with logo and Time*/}
            <div className="w-full max-w-lg text-center">
                <Header />
                <div className="text-lg font-bold text-gray-500"><br/>
                    <span>Start: {gameTime === '0.5' ? '00:30' : gameTime + ':00' }</span><span> - End </span> {formatTime(time)}
                </div>
            </div>

            {/* Guess Grid and Answer */}
            <div className="w-full max-w-lg grid grid-cols-4 gap-1">
                {generateGridGuesses}
                <div className="col-span-4 w-full border border-2 border-gray-400"></div>
                <div className="col-span-4 ml-auto mr-auto">
                    <span className="text-lg font-bold text-gray-500">Answer:&nbsp;
                        <span className="text-lg font-bold text-red-400">{gameStatus === 'progress' ? '????' : randomNumber}&nbsp;</span>
                        / Enter To Continue
                    </span>
                </div>
                <div className="col-span-4 w-full border border-2 border-gray-400"></div>
            </div>

            {/* Virtual Keypad*/}
            <div className="w-full max-w-lg text-center">
                <div className="grid grid-cols-4 gap-1 bg-gray-700 p-2">
                    {generateVirtualKeypad()}
                </div>
                <div className="col-span-4 mt-1 text-center">
                    <span className="text-lg font-bold text-gray-500">
                        Guess Four Digits Then Press Enter
                    </span>
                </div>
                <div className="col-span-4 w-full border border-2 border-gray-400"></div>
                <span className="text-lg text-gray-500">Copyright CPC Systems</span><br/><br/>
            </div>
        </div>
    )
}

export default Play;
